const drinks = [{
  id: 19,
  title: "Drinks",
  category: "drinks",
  img: "./images/item-19.jpeg",
  price: "$2.49 $3.39 $1.65 $1.99 $1.50 $1.75 $2.49 $2.49 $1.69 $3.50 $2.49",
  desc: "Large Fountain Drink ————————— " +
  "\n X-Large Fountain Drink ———————— " +
  "\n Coffee (Small) ————————————  " +
  "\n Coffee (Large) —————-————-——  " +
  "\n Water (Bottle) ———————————— " +
  "\n Green Tea (Hot) ———————————  " +
  "\n Milk ———————————-—————  " +
  "\n Orange Juice ———————-—————  " +
  "\n Canned Soda ————————————  " +
  "\n Monster Energy Drink —————-———  " +
  "\n Gatorade or Arizona Tea ——————— "
  },
];
  export default drinks;